
//NAVIGATION
 nav {
     width:100%;
     display: flex;
     justify-content: space-between;
     align-items: center;
     
     img {
         width: 4rem;

         @media screen and (max-width: 892px) {
             width: 3rem;
         }
     }

     .buttons {
         .btn-primary {
             background-color: transparent;
             border: none;
             font-size: 1.2rem;
             cursor: pointer;
             transition: all 0.3s ease-in-out;

             a {
                 text-decoration: none;
                 color: $pink-dark;
                 transition: all 0.3s ease-in-out;
             }

             @media screen and (max-width: 892px) {

                 font-size: 1rem;
             }
         }

         .btn-primary:hover {
             a {
                 color: $cyan-green;
             }

         }

         .btn-secondary {
             background-color: transparent;
             padding: 1rem 2rem;
             margin-left: 1rem;
             border: 1px solid $pink-dark;
             border-radius: 5rem;
             color: $pink-dark;
             font-weight: bold;
             font-size: 1.2rem;
             cursor: pointer;
             transition: all 0.3s ease-in-out;

             a {
                 text-decoration: none;
                 color: $pink-dark;
                 transition: all 0.3s ease-in-out;
             }

             @media screen and (max-width: 892px) {
                 padding: 0.8rem 1.5rem;
                 font-size: 1rem;
             }
         }

         .btn-secondary:hover {
             background-color: $pink-dark;

             a {
                 color: white;
             }
         }

     }
 }
.hero{
    width: 70%;
    
    padding-top: 4rem;
    padding-bottom: 2rem;
    display:flex;
    flex-direction: column;
    gap:2rem;
    margin: 0 auto;
    @media screen and (max-width: 450px) {
            width:90%;
    
        }
.hero-top{

    
    
   
    .hero-content{
        
        text-align: center;
        color:$gray-dark;
        @media screen and (max-width: 450px) {
            padding-top: 1.5rem;
            
        }
        h1{
            font-size:4rem;
            font-weight: 400;
            margin-bottom:1rem;
            margin-top: 1rem;
            @media screen and (max-width: 892px) {
                    
                    font-size: 3rem;
                }
        }
        p{
            font-size:1.6rem;
            font-weight: 600;
            margin-bottom:1rem;
        }
    }
}
    .my-img{

        display:flex;
        justify-content:center;
        margin:0 auto;
        img{
            width:13rem;
            border-radius:50%;
            border:1px solid $cyan-green;
            box-shadow: 0 0 0.5rem $cyan-green;
            @media screen and (max-width: 892px) {
                        
                        width: 10rem;
                }
        }
    }
    .hero-img {
        display:flex;
        justify-content:center;
       
        height: auto;
       
       
        img{
            width:100%;
            height:100%;
            border-radius: 10px;
            box-shadow: 0 0 0.5rem $cyan-green;
            @media screen and (max-width: 650px) {
            
                    width: 100vw;
                }
        }
    }
}

.about-me{
    width: 100%;
    margin-top: -2rem;
    background-color: $cyan-green;
    padding:2rem;
    height: 30rem;
    display:flex;
    align-items: flex-start;
    justify-content: center;
@media screen and (max-width: 550px) {
    align-items: center;
        height: 35rem;
    }
        @media screen and (max-width: 450px) {
            
            height: 45rem;
        }
    .content{
        margin-top: 2rem;
        width:40rem;
        text-align: center;
        
        @media screen and (max-width: 700px) {
                    
                        
                        margin-top: 0;
        }
       
        
        h3{
            margin-bottom:3rem;
            font-size: 2rem;
            color:$gray-dark;
        }
        p{
            font-size: 1.3rem;
            line-height: 1.6rem;
            letter-spacing: 0.5px;
            color:$gray-dark;
            font-weight: 600;
        }
    }


}



.skills{
    position: relative;
    width:100%;
    margin: 0 auto;
    
    .container{
        
       margin: 0 auto;
        position: relative;
        z-index: 1;
        top: -10rem;
        background-color: #fff;
        width:70%;
       display:flex;
         justify-content: center;
            align-items: center;
        
        border-radius: 10px;
        box-shadow: 0 0 0.5rem $cyan-green;
        @media screen and (max-width: 1009px) {
                flex-direction: column;
                
            }
        @media screen and (max-width: 550px) {
        width:100%;
        top:-5rem;
        
        }
        
        .fullstack{
            
           text-align: center;
            display:flex;
            flex-direction: column;
            gap:1rem;
            align-items: center;
            border-right: 1px solid $cyan-green;
            width:100%;
            height: 100%;
            padding: 4rem;
             @media screen and (max-width: 1009px) {
             border-right: none;
             border-bottom: 1px solid $cyan-green;
            }
            
            
            img{
                width:5rem;
                background-color: $cyan-green;
                border-radius: 50%;
                padding:0.5rem;
            }
            h1{
                color:$gray-dark;
                @media screen and (max-width: 1009px) {
                    font-size:1.5rem ;
                }
            }
            p{
                width:100%;
                font-size: 1.3rem;
            }
            .skills-body{
                h2{
                    margin-top: 1rem;

                }
                p{
                    margin-top: 0.5rem;
                }
                
                ul{
                    list-style-type: none;
                    display:flex;
                    flex-direction: column;
                    gap:1rem;
                    li{
                        text-align: center;
                        font-size: 1.3rem;
                        span{
                            color:$gray-dark;
                            font-weight: 600;
                            margin-right: 5px;
                        }
                    }
                }
            }
            

        }
        .mentor{
            align-self: flex-start;
            text-align: center;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: center;
                justify-content: flex-start;
                border-right: 1px solid $cyan-green;
                width: 100%;
                height: 100%;
                padding: 4rem;
            
                @media screen and (max-width: 1009px) {
                    border-right: none;
                    border-bottom: 1px solid $cyan-green;
                }
            
            
                img {
                    width: 5rem;
                    background-color: $cyan-green;
                    border-radius: 50%;
                    padding: 0.5rem;
                }
            
                h1 {
                    color: $gray-dark;
            
                    @media screen and (max-width: 1009px) {
                        font-size: 1.5rem;
                    }
                }
            
                p {
                    width: 100%;
                    font-size: 1.3rem;
                }
            
                .skills-body {
                    h2 {
                        margin-top: 1rem;
            
                    }
            
                    p {
                        margin-top: 0.5rem;
                    }
            
                    ul {
                        list-style-type: none;
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
            
                        li {
                            text-align: center;
                            font-size: 1.3rem;
            
                            span {
                                color: $gray-dark;
                                font-weight: 600;
                                margin-right: 5px;
                            }
                        }
                    }
                }
          
        }
    }
}

.portfolio-container{
    padding-bottom:5rem;
   p{
         text-align: center;
         font-size: 2.5rem;
         font-weight: 600;
         margin-bottom: 2rem;
        color:$gray-dark;
   }
    .projects-container{
        margin: 0 auto;
        width:70%;
        display:grid;
        grid-template-columns: repeat(3, 1fr);
        gap:2rem;
        @media screen and (max-width: 900px) {
            grid-template-columns: repeat(2,1fr);
        }
        @media screen and (max-width: 600px) {
         grid-template-columns: repeat(1, 1fr);
         width: 100%;
        }
         @media screen and (max-width: 400px) {
            
        }
      .project-card{
        position:relative;
        overflow: hidden;
        cursor:pointer;
        border-radius: 1rem;
        padding:2rem;
        @media screen and (max-width: 400px) {
            border-radius: 0;
            
            
        }
        .hover-content{
            width:100%;
            position:absolute;
            z-index: 999;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
            opacity:0;
            transition: all 0.3s ease-in-out;
            display: flex;
            height:100%;
            justify-content: center;
            align-items: flex-end;
            padding-bottom: 1rem;

            gap:2rem;
            a{  display:flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                color:white;
                font-size: 1.5rem;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
                
               &:hover{
                   color:$pink-dark;
               }
            }

        }
        img{
            position: relative;
            
            width:100%;
            height:100%;
           
        }   
        .overlay{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            opacity:0.7;
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 1;
            @media screen and (max-width: 400px) {
                padding-left: 3rem;
                padding-right:3rem;
                
            }
            p{
               
                color:white;
                font-size: 1.5rem;
                font-weight: 500;
                text-align: center;
                transition: all 0.3s ease-in-out;
                margin:0;
                @media screen and (max-width: 600px) {
                    font-size: 1rem;

                }

            }
            img{
                width:6rem;
                height:6rem;
            }
            
        }
        &:hover{
            .hover-content{
                opacity:1;
            }
            .projectDescription{
                opacity:0;
            }
        }

       }
    }
}

.testimonials-container{
  
    
    p{
        text-align: center;
        font-size: 2.5rem;
            font-weight: 600;
            margin-bottom: 2rem;
            margin-top: 2rem;
            color: $gray-dark;
    }
    .slider-container{
        position: relative;
            width: 33rem;
            margin: 0 auto;
            overflow: hidden;
            padding-bottom: 2rem;
            @media screen and (max-width: 550px) {
                width: 20rem;
                
            }

            .slider-track{
                width: 33rem;
                display: flex;
                transition: transform 0.3s ease-in-out;
                @media screen and (max-width: 550px) {
                        width: 20rem;
                
                    }
                .testimonial-card{
                    width:33rem;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap:1rem;
                    padding: 3rem;
                    @media screen and (max-width: 550px) {
                            width: 20rem;
                    
                        }
                    
                    img{
                        width:10rem;
                        border-radius:50%;
                        border:1px solid $cyan-green;
                        box-shadow: 0 0 0.5rem $cyan-green;
                    }
                    p{
                        font-size: 1.5rem;
                        margin-top: 1rem;
                    }
                    div{
                        h3{
                            text-align: center;
                        }
                        p{
                            
                            font-size: 1.3rem;
                            color:$gray-dark;
                        }
                    }
                    

                }


            }
        .slider-buttons{
            display:flex;
            justify-content: center;
            .slider-button{
                width: 12px;
                height: 12px;
                background-color: #ccc;
                border-radius: 50%;
                margin: 0 4px;
                cursor: pointer;
                  &.active {
                         background-color: $pink-dark;
                    }
            }
        }            
    }
}

.startProject{
   padding:2rem;
    background-color: pink;
    display:flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin:0 auto;
    font-size: 1.4rem;
    border-radius: 1rem;
    margin-bottom: -5rem;
    position: relative;
     @media screen and (max-width: 800px) {
        padding:3rem;
        flex-direction: column;
        gap:2rem;
       
        width:100%;
        border-radius: 0;
    }
    @media screen and (max-width: 450px) {
        margin-top: 3rem;
        padding:5rem;
        gap:3rem;
    }
    h1{
        font-size:1.8rem;
        flex:1;
        text-align: center;
        
    }
    p{
        text-align: center;
        flex:1;
        font-size: 1.3rem;
        line-height: 1.2;
        letter-spacing: .3px;
        font-weight: 500;
        @media screen and (max-width: 800px) {
            width:90%;
            
        }
    }

        .startProjectBtn{
           flex:1;
          
            display:flex;
            align-items: center;
            justify-content: center;
            a{ color:black;
               text-decoration: none; 
               display:flex;
               align-items: center;
               justify-content: center;
                border: 1px solid $pink-dark;
                padding-top:0.5rem;
                padding-bottom:0.5rem ;
                border-radius:4rem;
                width:11rem;
                cursor:pointer;
                transition: all 0.3s ease-in-out;
                &:hover{
                    background-color: $pink-dark;
                    
                }
            span{
                
                img{
                    display: flex;
                    width:2rem;
                
                }
            }

           .sp-btn{
                margin-left: 0.5rem;
                font-size: 1.1rem;
                
           }
        }
        }
    
}

.footer-container {
    
    height: 60vh;
    display: flex;
    flex-direction: column;
    background-color: $cyan-green;
    justify-content: flex-end;
    gap:3rem;
    padding:3rem;
    align-items: center;
    width:100%;
    .logo-container{
        .footer-logo{
            width: 5rem;
        }
    }
    div{

        .footer-social{

            list-style: none;
            display:flex;
            gap:2rem;
            .social-link{
               
                a{ 
                    svg{
                        color: $pink-dark;
                        cursor: pointer;
                        transition: all 0.3s ease-in-out;
                       
                    }
                }
                &:hover{
                    svg{
                        color: $pink-light;
                    }
                }
            }

        }
    }
}

.contact-form-container {
    .notification{
        text-align: center;
        color:red;
        font-size: 1.5rem;
    }
    margin: 0 auto;
    margin-top: 5rem;
    padding: 20px;
   
    border-radius: 4px;
    background-color: #fff;

    h1 {
        text-align: center;
        margin-bottom: 1rem;
        font-weight: 300;
        font-size: 3rem;
        color:$gray-dark;
        @media screen and (max-width: 800px) {
            font-size: 2rem;
            
        }
        @media screen and (max-width: 550px) {
            font-size: 1rem;
            font-weight: 600;
            
        }
    }

    form {
        width:60%;
        margin: 0 auto;
        margin-top: 5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .first{
            display:flex;
            gap:1rem;
            
            .input-wrapper{
                flex:1;
                input{
                    width:100%;
                    height:3rem;
                    padding:0.5rem;
                    border:1px solid #ccc;
                    &:focus{
                        outline:none;
                    }
                }
                select {
                        width: 100%;
                        height: 3rem;
                        padding: 0.5rem;
                        border: 1px solid #ccc;
                
                        &:focus {
                            outline: none;
                        }
                }
            }
            @media screen and (max-width: 650px) {
                flex-direction: column;
               
                
            }
        }
        .form-group{
            label{
                display:block;
                margin-bottom: 0.5rem;
                color:$gray-dark;
                font-weight: 600;
            }
            textarea{
                width:100%;
                height:15rem;
                border:1px solid #ccc;
                &:focus{
                    outline:none;
                }
            }
           
        }
        .fbtn{
            margin: 0 auto;
            button{
                width:100%;
                padding: 0.7rem 2rem;
                border: 1px solid $pink-dark;
                border-radius: 50rem;
                background-color: transparent;
                color:$pink-dark;
                font-size: 1.1rem;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                &:hover{
                    background-color: $pink-dark;
                    color:white;
                }
            }
           
        }
       
     
    }
}



.form-header{
    position: relative;
    border-bottom: 1px solid #ccc;
    width:100vw;
   nav{
    margin:0 auto;
    width:70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
    img{
        width: 4rem;
    }
    .buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        gap:1rem;
        
        img{
            width: 1.7rem;
            cursor: pointer;
        }
        .delete-icon{
            width: 1.5rem;
            cursor: pointer;
        }
        button{
            border:none;
            background-color: transparent;
        }

    }
   }
   .myImg{
    position:absolute;
    width:5rem;
    border-radius: 50%;
    bottom: 0;
    left:50%;
    transform: translate(-50%, 50%);
    border:1px solid $cyan-green;
    background-color: #fff;

   }
   
}
